.page_404{
    position: absolute;
    padding:40px 0;
    width: 100%;
    height: 100%;
    background-color:#fff !important; 
    font-family: 'Arvo', serif;
}

.page_404 img{ 
    margin-bottom:50;
    width:50%;
}

.four_zero_four_bg{
    height: 400px;
    background-position: center;
 }
 
 
 .four_zero_four_bg h1{
    font-size:80px;
 }
 
.four_zero_four_bg h3{
    font-size:80px;
}
            
.link_404{			 
    color: #fff!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
}
.contant_box_404{ margin-top:-50px;}